<template>
  <main class="p-3 px-4 container">
    <h2 class="font-weight-300 text-primary-light">
      You can edit your selected rooms names for clarification purposes.
    </h2>
    <div class="edit-names__grid mt-5">
      <EditSpace
        v-for="(space, index) in rooms"
        :name="space.typeName"
        :type-value="space.typeValue"
        :type-name="space.typeName"
        :key="index"
        v-model="space.name"
      />
    </div>
  </main>
</template>

<script>
import safeRequire from "@/mixins/safeRequire";
import EditSpace from "@/components/EditSpace";

export default {
  name: "EditNames",
  mixins: [safeRequire],
  components: {
    EditSpace,
  },
  data() {
    return {
      isSubmitted: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmitted) {
      next();
      return;
    }

    if (to.name === "CreateProject8") {
      this.$store.dispatch("room/submit").then(() => {
        this.isSubmitted = true;
      });
    } else {
      next();
    }
  },
  computed: {
    rooms: function () {
      return this.$store.state.room.selectedRoomTypes.filter(
        (r) => r.name !== "Project"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-names {
  border: 2px solid var(--neutral-light);
  border-radius: 0.5rem;
  padding: 1rem;

  &__room-icon {
    border: 2px solid var(--neutral-light);
    border-radius: 5%;
    padding: 15%;
    overflow: visible;
    user-select: none;
  }

  &__grid {
    display: grid;
    place-content: center;

    $i: 1;

    $grid-breakpoints: (
      xs: 0,
      s: 1,
      md: 768px,
      xl: 1200px,
      xxl: 1600px,
    );

    @each $name, $value in $grid-breakpoints {
      @media (min-width: $value) {
        grid-template-columns: repeat(($i + 1), minmax(0, 1fr));
        gap: Min((0.75 + (($i - 1) * 0.5)) + rem, 1rem);
      }

      $i: $i + 1;
    }
  }
}
</style>
