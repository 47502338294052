export default {
  methods: {
    safeRequireIcon: function (path) {
      try {
        return require(`../assets/icons/${path}`);
      } catch (e) {
        return undefined;
      }
    },
  },
};
