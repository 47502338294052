<template>
  <div class="projectSpace projectSpace-selected">
    <div class="projectSpace__content p-3">
      <div class="d-flex flex-column wd-75p h-100 justify-content-between">
        <square-container class="wd-75p">
          <img
            :alt="name"
            class="projectSpace__room-icon"
            :src="
              require(`@/assets/icons/room-types/${typeName.replaceAll(
                ' ',
                '-'
              )}.png`)
            "
          />
        </square-container>
      </div>
      <div class="d-flex justify-content-between mt-3 w-100 gap-1">
        <input
          class="projectSpace__room-name text-white mb-0 w-100 overflow-hidden"
          :value="value"
          @input="onInput"
          :disabled="isDisabled"
          ref="input"
          @blur="isDisabled = true"
        />

        <v-icon
          @click="editClicked"
          class="cursor-pointer p-0"
          color="#fff"
          size="28px"
        >
          mdi-pencil-box-outline
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import SquareContainer from "@/components/SquareContainer";

export default {
  name: "EditSpace",
  components: { SquareContainer },
  props: {
    selected: Number,
    name: String,
    disableCount: Boolean,
    typeName: String,
    typeValue: Number,
    value: String,
  },
  data() {
    return {
      isDisabled: true,
    };
  },
  methods: {
    onInput(event) {
      console.log(event.target.value);
      this.$emit("input", event.target.value);
    },
    editClicked() {
      this.isDisabled = false;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bg-primary-light {
  background-color: var(--primary-light);
}

.projectSpace {
  --background: var(--neutral-dark);
  --border-color: var(--neutral-light);
  --text-color: var(--neutral-light);

  width: 100%;
  background: var(--background);
  position: relative;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-selected {
    --background: var(--blue-3);
    --border-color: var(--blue-2);
    --text-color: var(--neutral-light);
    --counter-display: block;
  }

  &__room-icon {
    fill: var(--text-color);
    border: 2px solid var(--border-color);
    border-radius: 12%;
    padding: 15%;
    overflow: visible;
    user-select: none;
  }

  &__room-name {
    color: var(--text-color);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.25rem;

    &:focus-visible {
      background: white;
      outline: 2px solid black;
      border-radius: 4px;
      padding-left: 4px;
      color: black !important;
    }
  }
}
</style>
